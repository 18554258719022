import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import Slide1 from 'src/slides/desktop/de/chapter_3/sub_2/slide1';
import Slide2 from 'src/slides/desktop/de/chapter_3/sub_2/slide2';
import SEO from 'src/components/_shared/seo';

import WrapperMobile from 'src/slides/mobile/de/chapter_3/sub_2/wrapperMobile';
import { isMobile } from 'react-device-detect';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Józef Piłsudski | Schlacht bei Warschau" description="Der Mensch, der die Bolschewiki anhielt. Lernen Sie seine Geschichte kennen." lang="de" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Józef Piłsudski | Schlacht bei Warschau" description="Der Mensch, der die Bolschewiki anhielt. Lernen Sie seine Geschichte kennen." lang="de" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
